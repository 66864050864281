import React, {useContext} from 'react';
import LangContext from '../components/context/langContext';
import PublicationContent from '../components/publication/publicatonContent';
import Layout from '../components/template/layout';
import { GetPublicationSeoEN } from '../content/pages/actions/publication/seo/en/GetPublicationSeo';
import { GetPublicationSeoFR } from '../content/pages/actions/publication/seo/fr/GetPublicationSeo';

const Publications = () => {
    const ctx = useContext(LangContext)
    const content = ctx.lang === 'fr' ? GetPublicationSeoFR() : GetPublicationSeoEN()
    return (
        <Layout
            title={content.pageTitle}	
            lang={ctx.lang}
            description={content.pageDescription}
            page="publications"
        >
            <div className="content-container">
                <PublicationContent
                    lang={ctx.lang}
                />
            </div>
        </Layout>
    );
};

export default Publications;