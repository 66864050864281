import React from 'react';
import { GetPublicationEN } from '../../content/pages/actions/publication/publicationBlock/en/GetPublication';
import { GetPublicationFR } from '../../content/pages/actions/publication/publicationBlock/fr/GetPublication';
import './publications.scss';

const PublicationContent = ({lang}) => {
    const content = lang === 'fr' ? GetPublicationFR() : GetPublicationEN()
    return (
        <div>
            <h2
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >{content.publicationTitle} </h2>
            {content.publicationContent.map((e,i)=>(
                <p>{e}</p>
            ))}
            <button className="publication-btn">{content.publicationContact}</button>
        </div>
    );
};

export default PublicationContent;